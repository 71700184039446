<template>
  <div class="recordInfo">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div>{{infoData.EvaluateTitle}}</div>
      <div></div>
    </div>
    <div class="mainVideo">
      <video :src="VideoPath" controls="controls" webkit-playsinline="true" playsinline="true" style="height:90%">
        your browser does not support the video tag
      </video>
    </div>
    <div class="main">

      <div class="mainHeader">
        <p class="title">{{infoData.EvaluateTitle}}</p>
        <p class="score">
          <i class="el-icon-alixunzhang"></i>
          <span>{{infoData.Result}}/{{infoData.Total}}</span>
        </p>
      </div>
      <div class="mainInfo">
        <!-- <span>作业完成时间：{{infoData.AddTime}}</span> -->
        <span>被评人：{{studentName}}</span>
        <button class="dianping" @click="dpshow">语音点评</button>
        <!-- <img src="../../../assets/img/dianping.png" class="dianping" @click="dpshow" /> -->
      </div>
      <el-table :data="tableData" :span-method="objectSpanMethod" border style="width: 100%;margin-top:12px;" :header-cell-style="{'background':'#E8E8E8','font-weight':'bold','color':'black'}">
        <el-table-column prop="Item1" label="分类" width="70">
        </el-table-column>
        <el-table-column prop="Title" label="评分项">
        </el-table-column>
        <el-table-column label="得分/总分" width="90" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.Score}}/{{scope.row.MaxScore}}</span>
          </template>
        </el-table-column>
      </el-table>
      <p style="font-size:12px;font-weight:bold;margin-top:24px;">总结</p>
      <van-field v-model="infoData.Remark" type="textarea" placeholder="请输入评语" rows="4" autosize disabled />
    </div>
    <!-- 弹出框内容 -->
    <van-dialog v-model="audioshow" title="语音点评" confirmButtonText="关闭" @confirm="audioConfirm">
      <div class="dialogMain">
        <div style="height:300px;overflow: hidden;overflow-y:auto;">
          <el-table :data="auidoList" border style="width: 98%;margin-left:1%;margin-bottom: 60px;" :header-cell-style="{'background':'#E8E8E8','font-weight':'bold','color':'black'}">
            <el-table-column align="center" label="录音视频截点" width="120">
              <template slot-scope="scope">
                <span>{{scope.row.Remark.split('|')[0]}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="audiotime" label="录音时长">
              <template slot-scope="scope">
                <span>{{scope.row.Remark.split('|')[1]}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <img src="../../../assets/img/open.png" class="operate" @click="openaudio(scope.row.Message)" />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </van-dialog>
    <audio id="audio" :src="audiosrc" style="display:none;" />
  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      tableData: [],
      Item1: "", // 一级分类
      spanArr: [], //二维数组，用于存放单元格合并规则
      position: 0, //用于存储相同项的开始index
      jobTaskLogID: "",
      studentID: "", // 学生ID
      infoData: {},
      VideoPath: "",
      studentName: "",
      audioshow: false,
      audiosrc: "",
      auidoList: [],
    }
  },
  methods: {
    // 获取列表数据
    initData() {
      this.$axios.get('/api/JobTask/TeacherEvaluateLog?jobTaskLogID=' + this.jobTaskLogID, "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data);
        if (data.code == 200) {
          this.tableData = data.data.EvaluateItemsList;
          this.infoData = data.data;
          this.VideoPath = data.data.VideoPath;
          this.rowspan(0, "Item1");
          this.auidoList = data.data.CommentsList;
        }
      });
    },
    // 点击头部左上角返回
    back() {
      this.$router.push({ name: "teacherworklist" });
    },
    //表格单元格合并
    rowspan(idx, prop) {
      this.spanArr[idx] = [];
      this.position = 0;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr[idx].push(1);
          this.position = 0;
        } else {
          if (this.tableData[index][prop] === this.tableData[index - 1][prop]) {
            this.spanArr[idx][this.position] += 1; //有相同项
            this.spanArr[idx].push(0); // 名称相同后往数组里面加一项0
          } else {
            this.spanArr[idx].push(1); //同列的前后两行单元格不相同
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      for (let i = 0; i < this.spanArr.length; i++) {
        if (columnIndex === i) {
          const _row = this.spanArr[i][rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },
    // 点击点评按钮
    dpshow() {
      this.audioshow = true;
    },
    //关闭点评弹框
    audioConfirm() {
      var _audio = document.getElementById("audio");
      _audio.pause();
    },
    // 点击播放按钮
    openaudio(src) {
      var _audio = document.getElementById("audio");
      _audio.pause();

      this.audiosrc = src;
      setTimeout(() => {
        _audio.play();
      }, 1000);

      console.log("播放音频" + src)
    },
    // 转换秒数
    getHMS(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime >= 60) {
        minuteTime = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (minuteTime >= 60) {
          hourTime = parseInt(minuteTime / 60);
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime)) + "秒";
      var result2 = "" + (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + "分" + result;
      var result3 = "" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime) : parseInt(hourTime)) + "时" + result2;
      if (parseInt(hourTime) == 0) {
        return result2;
      } else {
        return result3;
      }
    },
  },
  mounted() {
    this.jobTaskLogID = this.$route.query.jobTaskLogID;
    this.studentID = Cookies.get('studentID');
    this.studentName = this.cookies.kkGet("stuName");
    this.initData();
  },
}
</script>

<style>
.recordInfo .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
  z-index: 9999;
}

.recordInfo .header i {
  margin-left: 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}

.recordInfo .header div:last-child {
  width: 30px;
}
.mainVideo {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  top: 40px;
  text-align: center;
  position: fixed;
  background: white;
  z-index: 900;
}
.recordInfo .main {
  width: 100%;
  min-height: calc(100vh - 44px);
  margin-top: 75%;
  padding: 15px;
}

.recordInfo .main .mainHeader {
  display: flex;
  justify-content: space-between;
}

.recordInfo .main .mainHeader .title {
  font-size: 16px;
  font-weight: bold;
}

.recordInfo .main .mainHeader .score {
  font-size: 20px;
  font-weight: bold;
  color: #2acc9b;
}

.recordInfo .main .mainHeader .score i {
  font-size: 24px;
  margin-right: 10px;
}

.recordInfo .main .mainInfo {
  margin-top: 12px;
}

.recordInfo .main .mainInfo span {
  color: #676767;
  font-size: 12px;
}

.recordInfo .main .mainInfo span:first-child {
  margin-right: 20px;
}

.recordInfo .main .van-cell {
  padding: 0;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.recordInfo .main .van-field__control {
  padding: 10px;
}
.dianping {
  position: relative;
  left: 45%;
  width: 75px;
  background-color: white;
  border-width: 0.5px;
  border-color: #3294ff;
  border-style: double;
  color: #3294ff;
}
.operate {
  width: 30px;
  margin-left: 5px;
}
</style>